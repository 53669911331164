import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { gql } from "~/__generated__"
import invariant from "tiny-invariant"
import { getMetaVar } from "~/common/getMetaVar"
import { signInTokenPath, tokenConfirmationPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Button } from "~/ui/button"
import { OnboardingLayout } from "../layouts/OnboardingLayout"
import { OTPInput, SlotProps, REGEXP_ONLY_DIGITS_AND_CHARS } from "input-otp"
import { cn } from "~/lib/utils"
import toast from "react-hot-toast"
import { OnboardingFieldLabel, SignInContent } from "~/ui/Onboarding"
import { CommunitySlug } from "~/__generated__/graphql"
import { useCommunityClassname } from "~/community/useCommunity"

export const RESEND_MAGIC_LINK_MUTATION = gql(`
  mutation ResendMagicLink($input: ResendMagicLinkInput!) {
    resendMagicLink(input: $input) {
      sessionId
    }
  }
`)

function Slot(props: SlotProps) {
  const ccls = useCommunityClassname()

  return (
    <div
      className={cn(
        "relative w-[50px] h-[64px] text-[2rem]",
        "flex items-center justify-center",
        "transition-all duration-300",
        "border-[1px] border-borderColor rounded-lg bg-white",
        { "border-blue-600": props.isActive },
        ccls({
          [CommunitySlug.Gotomillions]: "border-primary",
          default: "border-borderColor",
        })
      )}
    >
      {props.char !== null && <div>{props.char}</div>}
    </div>
  )
}

export const SignInTokenScreen = () => {
  const [resendMagicLink] = useSafeMutation(RESEND_MAGIC_LINK_MUTATION)
  const [token, setToken] = useState("")

  const { sessionId } = useParams()
  invariant(sessionId)

  const navigate = useNavigate()
  const csrfToken = getMetaVar("csrf-token")

  const sendAgain = async () => {
    const { data } = await resendMagicLink({
      variables: {
        input: {
          sessionId,
        },
      },
    })

    if (data?.resendMagicLink.sessionId) {
      toast.success("Code sent, please check your email")
      navigate(signInTokenPath({ sessionId: data.resendMagicLink.sessionId }), {
        replace: true,
      })
    } else {
      toast.error("Error sending code")
    }
  }

  return (
    <OnboardingLayout step={0}>
      <SignInContent>
        <div className="sign-in-content__header">
          Please check your email for the code provided
        </div>
        <div className="sign-in-content__form-container">
          <form method="POST" action={tokenConfirmationPath({ id: sessionId })}>
            <input type="hidden" name="_method" value="patch" />
            <input type="hidden" name="authenticity_token" value={csrfToken} />

            <label className="onboarding-field max-w-[380px] m-auto">
              <OnboardingFieldLabel className="text-dark-gray">
                6 Digit Code
                <span className="text-highlight">*</span>
              </OnboardingFieldLabel>
              <OTPInput
                value={token}
                onChange={(newValue: string) =>
                  setToken(newValue.toUpperCase())
                }
                maxLength={6}
                inputMode="text"
                containerClassName="group flex justify-between w-full mt-2 sign-in-otp"
                pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
                name="passwordless[token]"
                data-1p-ignore="true"
                render={({ slots }) => (
                  <>
                    {slots.map((slot, idx) => (
                      <Slot key={idx} {...slot} />
                    ))}
                  </>
                )}
              />
            </label>
            <Button
              className="w-full mt-9"
              variant="onboarding"
              disabled={token.length === 0}
              type="submit"
            >
              Confirm Code
            </Button>

            {/* eslint-disable jsx-a11y/anchor-is-valid */}
            <div className="sign-in-callout">
              Didn't receive the code?{" "}
              <a onClick={sendAgain} className="cursor-pointer">
                Send Again
              </a>
            </div>
          </form>
        </div>
      </SignInContent>
    </OnboardingLayout>
  )
}
